"use client";

import { Button } from "@/components/ui/button";
import { Match } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { Timestamp } from "firebase/firestore";

export const columns: ColumnDef<Match>[] = [
  {
    accessorKey: "date",
    header: "Round",
    cell: ({ row }) => {
      const formatted =
        row.original.match === 1
          ? (row.getValue("date") as Date).toLocaleDateString()
          : "";
      return <div>{formatted}</div>;
    },
  },
  {
    accessorKey: "home",
    header: "Home",
    cell: ({
      row: {
        original: { home, result },
      },
    }) => (
      <div className="flex items-center gap-5">
        {result !== null && (
          <div
            className={`h-3 w-3 rounded-full ${
              result[0] === 13 ? "bg-graph" : "bg-orange-700"
            }`}
          />
        )}
        <div>{home}</div>
      </div>
    ),
  },
  {
    accessorKey: "result",
    header: "Points",
    cell: ({
      row: {
        original: { result },
      },
    }) => (result === null ? "---" : result[0]),
    id: "home_result",
  },
  {
    accessorKey: "result",
    header: "Points",
    cell: ({
      row: {
        original: { result },
      },
    }) => (result === null ? "---" : result[1]),
    id: "away_result",
  },
  {
    accessorKey: "away",
    header: "Away",
    cell: ({
      row: {
        original: { away, result },
      },
    }) => (
      <div className="flex items-center gap-5">
        {result !== null && (
          <div
            className={`h-3 w-3 rounded-full ${
              result[1] === 13 ? "bg-graph" : "bg-orange-700"
            }`}
          />
        )}
        <div>{away}</div>
      </div>
    ),
  },
];
