import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAGYb_3e5usB-DXy4XHu7JdzQ-Prhqcaxg",
  authDomain: "bertanque-5ddf6.firebaseapp.com",
  projectId: "bertanque-5ddf6",
  storageBucket: "bertanque-5ddf6.appspot.com",
  messagingSenderId: "559519694091",
  appId: "1:559519694091:web:034b42f1fdb8b30d6c360e",
  measurementId: "G-LP7KEY1YJ7",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
