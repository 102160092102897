import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { CompletedMatch, Match, Person } from "@/types";
import {
  generateMannyQuote,
  getPetanqueMatchSummary,
} from "@/constants/general";
import { PLAYERS } from "@/App";
import _ from "lodash";
import { Balls } from "./in-progress";

export const MatchResult = ({
  match,
  handleClose,
}: {
  match: CompletedMatch;
  handleClose: () => void;
}) => {
  const homeWinner = match.result[0] === 13;
  const winner = homeWinner ? match.home : match.away;
  const loser = homeWinner ? match.away : match.home;
  const { rounds } = match;
  console.log(match);

  return (
    <div>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {homeWinner
            ? `Epic win at Home for ${winner}!`
            : `Unbelievable Away win for ${winner}!`}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {getPetanqueMatchSummary(winner, loser)}
        </AlertDialogDescription>
      </AlertDialogHeader>

      <div className="my-3 space-y-5">
        <div className="flex h-52 w-full items-center justify-center gap-10">
          <div className={`relative h-full`}>
            <img
              src={PLAYERS[winner].image}
              alt="Description"
              className="h-full w-full object-contain"
            />
          </div>
        </div>
        {rounds && (
          <div className="flex flex-wrap items-center justify-center gap-2 rounded-md border bg-card p-2">
            <div className="w-full text-center text-xs font-medium text-muted-foreground">
              {rounds.length} Rounds
            </div>

            {rounds.map((round, idx) => (
              <div
                className="flex h-6 w-6 items-center justify-center fade-in"
                key={`round_${idx}`}
              >
                <Balls
                  amt={round.reduce((a, b) => a + b, 0)}
                  player={round[0] === 0 ? match.away : match.home}
                  externalKey={idx}
                />
              </div>
            ))}
          </div>
        )}
        <div className="flex -translate-y-2 items-center justify-between">
          <div className="flex items-center gap-2">
            <div className={`h-2 w-2 rounded-full bg-${match.home}`} />
            <div className="text-xs font-medium opacity-70">{match.home}</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-xs font-medium opacity-70">{match.away}</div>
            <div className={`h-2 w-2 rounded-full bg-${match.away}`} />
          </div>
        </div>
      </div>
      <AlertDialogFooter>
        <AlertDialogCancel onClick={handleClose}>Close</AlertDialogCancel>
        {/* <AlertDialogAction
          >
            Resubmit
          </AlertDialogAction> */}
      </AlertDialogFooter>
    </div>
  );
};
