export const weekHighlights: {
  title: string;
  description: string;
  images: [any, any, any, any];
  video: any;
}[] = [
  {
    title: "Week 1",
    description: "What a cracker of a first week!",
    images: [
      require("@/assets/hall-of-fame/week_1/marcela.png"),
      require("@/assets/hall-of-fame/week_1/marcela.png"),
      require("@/assets/hall-of-fame/week_1/marcela.png"),
      require("@/assets/hall-of-fame/week_1/marcela.png"),
    ],
    video: require("@/assets/hall-of-fame/week_1/week_1_highlights.mp4"),
  },
  {
    title: "Week 2",
    description: "It was halloween week and it did not disappoint! Spooky",
    images: [
      require("@/assets/hall-of-fame/week_2/marcela.png"),
      require("@/assets/hall-of-fame/week_2/marcela.png"),
      require("@/assets/hall-of-fame/week_2/marcela.png"),
      require("@/assets/hall-of-fame/week_2/marcela.png"),
    ],
    video: require("@/assets/hall-of-fame/week_2/week_2_highlights.mp4"),
  },
];
