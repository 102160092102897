export const SEASON = 1;

export const mannyQuotes = [
  "Be on the look out for things that make you laugh. If you see nothing worth laughing at, pretend you see it, then laugh.",
  "Add a dab of lavender to milk; leave town with an orange, and pretend you're laughing at it.",
  "When you rest, you are a king surveying your estate. Look at the woodland, the peacocks on the lawn. Be the king of your own calm kingdom.",
  "When you're feeling under pressure, do something different. Roll up your sleeves, or eat an orange.",
  "Now, picture the ocean... AGH! AAGH! Oh, oh, a calm ocean!",
  "Add a drop of lavender to your bath, and soon, you'll soak yourself calm!",
  "Let go once in a while. You are a loose lily floating down an amber river.",
];

export const generateMannyQuote = () =>
  mannyQuotes[Math.floor(Math.random() * mannyQuotes.length)];

export function getPetanqueMatchSummary(
  playerA: string,
  playerB: string
): string {
  const summaries = [
    `In a thrilling showdown, ${playerA} showcased their skill with impressive throws, ultimately outshining ${playerB} in a match filled with laughter and unexpected twists that kept the crowd entertained.`,
    `The match between ${playerA} and ${playerB} was a rollercoaster of hilarity, featuring wild throws and unexpected collisions, but ${playerA} emerged victorious in a wonderfully chaotic display of petanque prowess.`,
    `${playerA} and ${playerB} battled it out in a delightful match where each shot brought laughter, but in the end, it was ${playerA} who took home the bragging rights amidst a backdrop of comedic antics.`,
    `Amidst cheers and chuckles, ${playerA} and ${playerB} went head-to-head in a thrilling petanque match, with ${playerA} ultimately claiming victory after a series of amusing and unpredictable moments.`,
    `The crowd roared as ${playerA} and ${playerB} faced off in an epic petanque match, filled with hilarious mishaps and joyful celebrations, culminating in ${playerA}’s well-deserved triumph.`,
    `In a lively contest full of surprises and laughter, ${playerA} outplayed ${playerB} in a spirited match that had everyone cheering for the entertaining antics both players delivered.`,
    `The petanque match between ${playerA} and ${playerB} was a delightful spectacle, featuring unexpected bounces and playful jests, with ${playerA} ultimately walking away as the victor.`,
    `In a wonderfully chaotic showdown, ${playerA} and ${playerB} kept the audience entertained with their quirky throws, leading to ${playerA}’s victory in a match that was as fun as it was competitive.`,
    `${playerA} and ${playerB} engaged in a whimsical petanque match that had the crowd in stitches, and in the end, ${playerA}’s charm and skill led to a well-earned victory.`,
    `The match unfolded with a delightful mix of skill and silliness, as ${playerA} ultimately triumphed over ${playerB}, leaving both players and spectators in high spirits.`,
  ];

  // Get a random index
  const randomIndex = Math.floor(Math.random() * summaries.length);
  return summaries[randomIndex];
}

export function getRandomPetanqueIntro(
  playerA: string,
  playerB: string
): string {
  const intros = [
    `Roll up, roll up! Today’s clash pits ${playerA}, the master of smooth throws and sharper quips, against ${playerB}, the boules whisperer who claims to know the secret language of metal! Will it be precision or pure passion? Only one way to find out—let’s get rolling!`,
    `Bonjour, mes amis! Get ready for a petanque match of epic proportions! ${playerA}, known to throw like a dream, takes on ${playerB}, who swears by a secret lucky beret. Will it bring them victory, or just an extra dash of panache? Brace yourselves for a magnifique match!`,
    `Ladies and gentlemen, today we have a showdown between ${playerA}, who’s been rumored to polish their boules with unicorn tears, and ${playerB}, the unstoppable force who once threw a boule through a baguette! Expect some serious style points—and maybe a few broken rules!`,
    `Attention, petanque fans! Today, ${playerA}, the reigning ‘Ruler of the Roll,’ takes on ${playerB}, the master of mischief who isn’t above using a little charm to distract their opponents! Who will roll out victorious? Hold onto your berets, folks!`,
    `Welcome, all, to today’s petanque face-off! ${playerA}, known to glide across the court with effortless grace, will be facing ${playerB}, who brings a bucket of confidence and an endless bag of sneaky strategies. It’s gonna be wild, wacky, and worth every second!`,
    `Mesdames et messieurs, prepare for petanque madness! ${playerA}, who claims to have once bowled a perfect game in total darkness, faces ${playerB}, who insists the court is their ‘personal playground.’ This match promises unexpected rolls, bold moves, and maybe some wild dance moves!`,
    `Hold onto your boules, everyone! It’s ${playerA}, who can spot a perfect throw from miles away, versus ${playerB}, who’s only strategy is ‘close enough’ but somehow makes it work. Who will win in this clash of precision vs. pure luck?`,
    `Ladies and gents, welcome to the ultimate petanque showdown! ${playerA}, the calculating precision roller, faces ${playerB}, the wildcard whose throws have been known to defy physics. Will science or sheer chaos win the day? Let’s find out!`,
    `Bienvenue! Today, it’s ${playerA}, who plays with laser focus, taking on ${playerB}, who plays with whatever snack they’re holding. Will victory go to focus or fun? This match is going to be a treat—snacks included!`,
    `Gather around for today’s most unpredictable petanque battle! ${playerA}, who practices daily with a side of existential philosophy, versus ${playerB}, who thinks of every throw as an art project. Hold on to your boules—it’s going to get deep!`,
    `Bonjour, petanque lovers! We have ${playerA}, the ‘Picasso of Petanque,’ known for throws that defy logic, against ${playerB}, who rolls with enough gusto to start a parade! Today’s game promises grand throws and grander stories—don’t miss it!`,
    `Here we go, folks! ${playerA}, who’s all about serious strategy, takes on ${playerB}, who’s just here for the laughs but somehow keeps winning! Get ready for an epic clash of focus versus folly—may the best boule win!`,
    `A warm welcome to today’s petanque brawl! ${playerA}, who’s known to measure every angle twice, faces ${playerB}, who lets the boules fly where they may. Will meticulous math or freewheeling fun rule today’s game? We’re about to find out!`,
    `Bonjour, and bienvenue! Today’s match has ${playerA}, who’s a walking petanque encyclopedia, against ${playerB}, who thinks petanque is a ‘game of feelings.’ Will knowledge or intuition reign supreme? Hold onto your boules, this is going to be enlightening!`,
    `Welcome to a showdown for the ages! It’s ${playerA}, known to talk strategy in their sleep, facing ${playerB}, whose lucky dance moves are rumored to scare the boules into submission. Who will roll to victory? The dance floor—I mean, the court—is ready!`,
    `Petanque fans, brace yourselves! We’ve got ${playerA}, the ‘Boule Scientist,’ up against ${playerB}, the ‘Roller of Destiny,’ who claims their throws are guided by cosmic forces. Science or stardust—who’s going to claim the win? Let’s find out!`,
    `Welcome, one and all, to today’s ultimate throwdown! ${playerA}, who calculates every throw like a pro, takes on ${playerB}, who plays by the ‘if it rolls, it counts’ rule. Will math or mayhem be the path to glory? Let’s find out!`,
    `Bonjour, mesdames et messieurs! Today we have ${playerA}, with a throw so precise they could hit a raisin at 20 meters, versus ${playerB}, who insists every throw has ‘a little heart in it.’ It’s precision vs. passion, and it’s going to be magnifique!`,
    `Roll up, roll up! Today’s match pits ${playerA}, who’s all about finesse, against ${playerB}, who plays like every throw is a grand finale. It’s technique versus theatrical flair—expect drama, delight, and maybe a few dance moves!`,
    `Gather round, folks! Today we have ${playerA}, known for throws as steady as the Eiffel Tower, against ${playerB}, whose unique technique often includes a ‘victory twirl.’ It’s going to be a lively, unpredictable, and downright delightful game!`,
  ];

  // Select a random introduction from the array
  const randomIndex = Math.floor(Math.random() * intros.length);
  return intros[randomIndex];
}
