export function nestedArrayToObjectOfArrays(input: [number, number][]): {
  [key: number]: [number, number];
} {
  return input.reduce((acc, array, index) => {
    acc[index] = array;
    return acc;
  }, {} as { [key: number]: [number, number] });
}

export function objectToArrayOfArrays(input: {
  [key: number]: [number, number];
}): [number, number][] {
  return Object.keys(input).map((key) => input[parseInt(key)]);
}
