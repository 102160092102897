import { ReactNode } from 'react';

export function TypographyH1({ children, className }: { children?: ReactNode; className?: string }) {
  return <h1 className={`scroll-m-20 text-4xl font-bold tracking-tight lg:text-5xl ${className}`}>{children}</h1>;
}

export function TypographyH2({ children, className }: { children?: ReactNode; className?: string }) {
  return <h2 className={`scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0 ${className}`}>{children}</h2>;
}

export function TypographyH3({ children, className }: { children?: ReactNode; className?: string }) {
  return <h3 className={`scroll-m-20 text-2xl font-semibold tracking-tight ${className}`}>{children}</h3>;
}

export function TypographyH4({ children, className }: { children?: ReactNode; className?: string }) {
  return <h4 className={`scroll-m-20 text-xl font-medium tracking-tight ${className}`}>{children}</h4>;
}

export function TypographyP({ children, className }: { children?: ReactNode; className?: string }) {
  return <p className={`leading-7 [&:not(:first-child)]:mt-6 ${className}`}>{children}</p>;
}

export function TypographyLead({ children, className }: { children?: ReactNode; className?: string }) {
  return <p className={`text-xl text-muted-foreground ${className}`}>{children}</p>;
}

export const HeaderWithLead = ({ header, lead }: { header: string; lead: string }) => {
  return (
    <div className="py-5">
      <TypographyH1>{header}</TypographyH1>
      <TypographyLead className="my-2 max-w-xl">{lead}</TypographyLead>
    </div>
  );
};
export const SmallHeaderWithLead = ({ header, lead, className, fadeIn }: { header: string; lead: string; className?: string; fadeIn?: boolean }) => {
  return (
    <div className={`${className} py-5`}>
      <h1 className={`scroll-m-20 text-3xl font-bold tracking-tight lg:text-4xl ${fadeIn ? 'fade-in' : ''}`} key={`header_${header}`}>
        {header}
      </h1>
      <TypographyLead className={`my-2 max-w-xl ${fadeIn ? 'fade-in' : ''}`} key={`lead_${lead}`}>
        {lead}
      </TypographyLead>
    </div>
  );
};
