/* eslint-disable @typescript-eslint/ban-ts-comment */
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "./components/ui/toaster";
// import ContextProvider from './Context';

if (localStorage.getItem("dark-mode") === "disabled")
  document.body.classList.remove("dark");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
    <Toaster />
  </BrowserRouter>
);
