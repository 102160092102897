import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { CompletedMatch, Match, Person } from "@/types";
import { generateMannyQuote } from "@/constants/general";
import { PLAYERS } from "@/App";
import _ from "lodash";
import { PreMatch } from "./pre-match";
import { MatchInProgress } from "./in-progress";

export const NewMatch = ({
  match,
  handleClose,
}: {
  match: Match;
  handleClose: () => void;
}) => {
  const [matchState, setMatchState] = useState<"default" | "in-progress">(
    "default"
  );

  const handleStartMatch = () => setMatchState("in-progress");

  return (
    <div className="flex h-screen max-h-[600px] flex-col items-center justify-center md:h-auto md:max-h-full">
      {matchState === "default" && (
        <PreMatch
          home={match.home}
          away={match.away}
          handleStartMatch={handleStartMatch}
          handleClose={handleClose}
        />
      )}
      {matchState === "in-progress" && (
        <MatchInProgress
          match={match}
          handleClose={handleClose}
          key="in-progress"
        />
      )}
    </div>
  );
};
