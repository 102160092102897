"use client";

import { PLAYERS } from "@/App";
import { Button } from "@/components/ui/button";
import { Ranking, RankingWithPlayer } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { Timestamp } from "firebase/firestore";

export const columns: ColumnDef<RankingWithPlayer>[] = [
  {
    accessorKey: "player",
    header: "Player",
    cell: ({ row }) => {
      return (
        <div className="min-w-36">
          <div className="flex shrink-0 items-center gap-5">
            <img
              src={PLAYERS[row.original.player].image}
              alt="aksldjf"
              className="aspect-square h-12 object-contain"
            />
            <div className="text-lg">{row.original.player}</div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "w",
    header: "W",
  },
  {
    accessorKey: "l",
    header: "L",
  },
  {
    accessorKey: "pf",
    header: "PF",
  },
  {
    accessorKey: "pa",
    header: "PA",
  },
  {
    accessorKey: "pd",
    header: "PD",
  },
];
