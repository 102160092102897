import { bertBaseball } from "@/App";
import {
  HeaderWithLead,
  TypographyH1,
  TypographyLead,
} from "@/components/typography";
import { Button, GradientOutlineButtonLarge } from "@/components/ui/button";
import { weekHighlights } from "./content";
import { Play, PlayCircle } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { generateMannyQuote } from "@/constants/general";
import { WeekHighlights } from "./week-highlights";

const HallOfFame = () => {
  return (
    <div className="w-full overflow-x-hidden fade-in">
      <div className="flex flex-col justify-between md:flex-row md:items-center md:gap-5">
        <div className="flex w-full items-center justify-between md:flex-col md:items-start md:justify-normal">
          <TypographyH1>Hall of Fame</TypographyH1>
          <TypographyLead className="my-2 hidden max-w-4xl md:block">
            Welcome to the Bértanque Hall of Fame! Here you will find all the
            Bertiest achievements, memories and highlights from the greatest
            game in existence.
          </TypographyLead>
          <img
            src={bertBaseball}
            alt="pertanque"
            className="max-h-12 md:hidden"
          />
        </div>
        <TypographyLead className="my-2 max-w-4xl md:hidden">
          Welcome to the Bértanque Hall of Fame! Here you will find all the
          Bertiest achievements, memories and highlights from the greatest game
          in existence.
        </TypographyLead>
        <img
          src={bertBaseball}
          alt="pertanque"
          className="hidden max-h-36 pb-4 md:block"
        />
      </div>
      <div className="mt-5 space-y-5 md:mt-0">
        {weekHighlights.map((week) => (
          <WeekHighlights week={week} key={`${week.title}_highlights_panel`} />
        ))}
      </div>
    </div>
    // <div
    //   className="mt-20 flex h-full w-full flex-col items-center justify-center space-y-5 p-5 fade-in
    //       "
    // >
    //   <img src={bertBaseball} alt="pertanque" className="max-h-40" />
    //   <TypographyH1>Coming Soon!!</TypographyH1>
    //   <TypographyLead className="my-2 max-w-xl text-center">
    //     Hall of Fame will show all the Bertiest achievements and memories from
    //     the best Bértanque club in the world!
    //   </TypographyLead>
    // </div>
  );
};

export default HallOfFame;
