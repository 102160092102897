import { SEASON } from "@/constants/general";
import { db } from "@/firebase";
import { FirestoreMatch, Match } from "@/types";
import { objectToArrayOfArrays } from "@/util/firestore-transform";
import { collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

export const useMatches = (season: number) => {
  const q = query(
    collection(db, "matches"),
    where("season", "==", season),
    orderBy("date"),
    orderBy("match")
  );
  const [value, loading, error] = useCollection(q);

  const matches = value?.docs
    ? value.docs.map((doc) => {
        const { date, rounds, ...data } = doc.data() as FirestoreMatch;
        return {
          ...data,
          date: new Date(date.seconds * 1000),
          rounds: rounds ? objectToArrayOfArrays(rounds) : null,
          id: doc.id,
        };
      })
    : [];

  return [matches, loading, error] as const;
};
