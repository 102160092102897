import { TypographyH1, TypographyLead } from "@/components/typography";
import { DataTable } from "./data-table";
import { columns } from "./columns";
import { useState } from "react";

import { useMatches } from "@/hooks/useMatches";
import { SEASON } from "@/constants/general";
import { Match, Person, Ranking, RankingWithPlayer } from "@/types";
import _ from "lodash";
import { bertThumbs } from "@/App";

const transformMatchesToRankings = (matches: Match[]) => {
  const rankings: { [key in Person]: Ranking } = {
    Tomasz: { w: 0, l: 0, pf: 0, pa: 0, pd: 0 },
    Robbie: { w: 0, l: 0, pf: 0, pa: 0, pd: 0 },
    Paul: { w: 0, l: 0, pf: 0, pa: 0, pd: 0 },
  };

  const updateRankings = (player: Person, result: [number, number]) => {
    const changes: Ranking = {
      w: Number(result[0] === 13),
      l: Number(result[0] !== 13),
      pf: result[0],
      pa: result[1],
      pd: result[0] - result[1],
    };
    const currentPlayerStats = rankings[player];
    for (const key in changes) {
      const typedKey = key as keyof Ranking;
      rankings[player][typedKey] =
        currentPlayerStats[typedKey] + changes[typedKey];
    }
  };
  matches.forEach((match) => {
    if (!match.result) return;
    updateRankings(match.home, match.result);
    updateRankings(match.away, match.result.toReversed() as [number, number]);
  });
  return Object.entries(rankings).map(([player, ranking]) => ({
    player,
    ...ranking,
  })) as RankingWithPlayer[];
};

const Schedule = () => {
  const [matches, loading, error] = useMatches(SEASON);
  console.log(loading);
  const rankings = transformMatchesToRankings(matches);
  const leader = error
    ? "no-one"
    : loading
    ? null
    : _.orderBy(rankings, ["w", "pd", "pf"], ["desc", "desc", "desc"])[0]
        .player;

  return (
    <div className="w-full overflow-x-hidden fade-in">
      <div className="flex flex-col justify-between md:flex-row md:items-center md:gap-5">
        <div className="flex w-full items-center justify-between md:flex-col md:items-start md:justify-normal">
          <TypographyH1>Rankings</TypographyH1>
          <TypographyLead
            className={`my-2 hidden max-w-4xl md:block ${
              leader === null ? "opacity-0" : "opacity-100"
            } transition-all duration-100`}
          >
            This seasons rankings! Looks like {leader} is in the lead!
          </TypographyLead>
          <img
            src={bertThumbs}
            alt="pertanque"
            className="max-h-12 md:hidden"
          />
        </div>
        <TypographyLead className="my-2 max-w-4xl md:hidden">
          This seasons rankings! Looks like {leader} is in the lead!
        </TypographyLead>
        <img
          src={bertThumbs}
          alt="pertanque"
          className="hidden max-h-32 md:block"
        />
      </div>

      {error ? (
        <>Error...</>
      ) : loading ? (
        <></>
      ) : (
        <div className="fade-in">
          <DataTable columns={columns} data={rankings} />
        </div>
      )}
    </div>
  );
};

export default Schedule;
