import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { CompletedMatch, Match, Person } from "@/types";
import {
  generateMannyQuote,
  getRandomPetanqueIntro,
} from "@/constants/general";
import { PLAYERS } from "@/App";
import _ from "lodash";
import { Balls } from "./in-progress";

export const PreMatch = ({
  home,
  away,
  handleStartMatch,
  handleClose,
}: {
  home: Person;
  away: Person;
  handleStartMatch: () => void;
  handleClose: () => void;
}) => {
  return (
    <div className="">
      <div>
        <AlertDialogHeader>
          <AlertDialogTitle>It's Match Time!</AlertDialogTitle>
          <AlertDialogDescription>
            {getRandomPetanqueIntro(home, away)}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div className="my-7 space-y-5">
          <div className="flex w-full items-center justify-center gap-10">
            <div className="flex w-[40%] flex-col items-center gap-3">
              <div className={`flex aspect-[8.5/10] h-full w-full`}>
                <img
                  alt="Description"
                  src={PLAYERS[home].image}
                  className={`h-full w-full object-contain animation-${home}`}
                />
              </div>
              <div className="h-6 w-6">
                <Balls amt={4} player={home} />
              </div>
              <div className="">Home</div>
            </div>
            <div className="flex w-[40%] flex-col items-center gap-3">
              <div className={`flex aspect-[8.5/10] h-full w-full`}>
                <img
                  alt="Description"
                  src={PLAYERS[away].image}
                  className={`h-full w-full object-contain animation-${away}`}
                />
              </div>
              <div className="h-6 w-6">
                <Balls amt={4} player={away} />
              </div>
              <div className="">Away</div>
            </div>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleClose}>Close</AlertDialogCancel>
          <AlertDialogAction onClick={handleStartMatch}>
            Start Match
          </AlertDialogAction>
        </AlertDialogFooter>
      </div>
    </div>
  );
};
