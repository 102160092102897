import { AlertDialog, AlertDialogContent } from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { CompletedMatch, Match } from "@/types";
import { generateMannyQuote } from "@/constants/general";
import _ from "lodash";
import { MatchResult } from "./match-result";
import { NewMatch } from "./match-new";

export function MatchDialog({
  open,
  handleClose,
  match,
}: {
  open: boolean;
  handleClose: () => void;
  match: Match;
}) {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        {match.result !== null ? (
          <MatchResult
            match={match as CompletedMatch}
            handleClose={handleClose}
          />
        ) : (
          <NewMatch match={match} handleClose={handleClose} />
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}

// const [quote, setQuote] = useState(generateMannyQuote());
//   useEffect(() => {
//     if (!open) {
//       setTimeout(() => {
//         setQuote(generateMannyQuote());
//         setOtherScore(undefined);
//         setWinner(undefined);
//       }, 200);
//     }
//   }, [open]);

//   const [winner, setWinner] = useState<"home" | "away">();
//   const [otherScore, setOtherScore] = useState<number>();

//   const handleSubmit = async () => {
//     const result = [
//       winner === "home" ? 13 : otherScore,
//       winner === "away" ? 13 : otherScore,
//     ];
//     await setDoc(doc(db, "matches", match.id), { result }, { merge: true });
//     handleClose();
//   };
