import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { CompletedMatch, Match, Person } from "@/types";
import { generateMannyQuote } from "@/constants/general";
import { PLAYERS } from "@/App";
import _ from "lodash";
import { Separator } from "@radix-ui/react-separator";
import { Button, ButtonIcon } from "@/components/ui/button";
import { Undo, Undo2, Undo2Icon } from "lucide-react";
import { nestedArrayToObjectOfArrays } from "@/util/firestore-transform";
import { useToast } from "@/hooks/use-toast";

const possibleScores = [1, 2, 3, 4];

export const MatchInProgress = ({
  match,
  handleClose,
}: {
  match: Match;
  handleClose: () => void;
}) => {
  const { toast } = useToast();

  const { id: matchId, home, away } = match;

  const [roundNumber, setRoundNumber] = useState(0);
  const [currentTotalScore, setCurrentTotalScore] = useState([0, 0]);
  const [rounds, setRounds] = useState<[number, number][]>([]);

  const handleChooseRoundScore = (score: [number, number]) => {
    setRounds((prev) => [...prev, score]);
    setCurrentTotalScore((prev) => _.zipWith(prev, score, (a, b) => a + b));
    setRoundNumber((prev) => prev + 1);
  };

  const handleUndoRoundScore = () => {
    if (roundNumber === 0) return;
    const previousRound = rounds.pop();
    setRounds((prev) => [...rounds]);
    setCurrentTotalScore((prev) =>
      _.zipWith(prev, previousRound, (a, b) => a - b)
    );
    setRoundNumber((prev) => prev - 1);
  };

  const handleSubmitResult = async () => {
    if (!leadingPlayer) return alert("Incomplete match");
    await setDoc(
      doc(db, "matches", matchId),
      {
        result: currentTotalScore,
        rounds: nestedArrayToObjectOfArrays(rounds),
      },
      { merge: true }
    );
    toast({
      title: `Great win for ${leadingPlayer}!`,
      description: PLAYERS[leadingPlayer].catchphrase,
      action: (
        <img
          src={PLAYERS[leadingPlayer].image}
          alt={leadingPlayer}
          className="h-16 w-16 object-contain"
        />
      ),
      duration: 10000,
      className: `bg-card border-${leadingPlayer}`,
    });
    handleClose();
  };

  const leadingPlayer = (() => {
    const diff = currentTotalScore[0] - currentTotalScore[1];
    return diff === 0 ? null : diff > 0 ? home : away;
  })();

  return (
    <div className="w-full fade-in">
      <div className="absolute left-0 top-5 w-full text-center text-xs font-light text-muted-foreground opacity-40 md:relative md:top-0 md:pb-5">
        {match.type === "regular_season" ? "Winter Season" : "Custom"} •{" "}
        {match.date.toDateString()} • Match {match.match}
      </div>
      <AlertDialogHeader>
        <AlertDialogTitle>Round {roundNumber + 1}</AlertDialogTitle>
        <AlertDialogDescription>{generateMannyQuote()}</AlertDialogDescription>
      </AlertDialogHeader>

      <div className={`mt-6 space-y-3`}>
        <div
          className={`space-y-1 ${
            currentTotalScore.includes(13) ? "pointer-events-none" : ""
          }`}
        >
          <div className="flex flex-wrap items-center justify-center gap-2">
            {possibleScores.map((score) => (
              <div
                key={`home_${score}`}
                onClick={() => handleChooseRoundScore([score, 0])}
                className={`flex h-14 w-14 items-center justify-center rounded-md border-2 border-border bg-card p-4 text-sm font-light transition-all hover:cursor-pointer hover:bg-accent ${
                  currentTotalScore[0] + score > 13 &&
                  "pointer-events-none opacity-50"
                }`}
              >
                <Balls amt={score} player={home} />
              </div>
            ))}
          </div>
          <div className="text-center text-xs font-light text-card-foreground opacity-40">
            {home}
          </div>
        </div>
        <div
          className={`flex items-center justify-center gap-5 pb-1 text-xl text-${
            leadingPlayer ?? "muted-foreground"
          }`}
        >
          <div>{currentTotalScore[0]}</div>
          <div>-</div>
          <div>{currentTotalScore[1]}</div>
        </div>
        <div
          className={`space-y-1 ${
            currentTotalScore.includes(13) ? "pointer-events-none" : ""
          }`}
        >
          <div className="flex flex-wrap items-center justify-center gap-2">
            {possibleScores.map((score) => (
              <div
                key={`away_${score}`}
                onClick={() => handleChooseRoundScore([0, score])}
                className={`flex h-14 w-14 items-center justify-center rounded-md border-2 border-border bg-card p-4 text-sm font-light transition-all hover:cursor-pointer hover:bg-accent ${
                  currentTotalScore[1] + score > 13 &&
                  "pointer-events-none opacity-50"
                }`}
              >
                <Balls amt={score} player={away} />
              </div>
            ))}
          </div>
          <div className="text-center text-xs font-light text-card-foreground opacity-40">
            {away}
          </div>
        </div>
        <div className="py-2">
          <div className="h-[1px] w-full bg-muted-foreground/30" />
        </div>
        <div className="flex flex-wrap gap-3">
          {rounds.length > 0 ? (
            rounds.map((round, idx) => (
              <div
                className="flex h-6 w-6 items-center justify-center fade-in"
                key={`round_${idx}`}
              >
                <Balls
                  amt={round.reduce((a, b) => a + b, 0)}
                  player={round[0] === 0 ? away : home}
                  externalKey={idx}
                />
              </div>
            ))
          ) : (
            <div className="w-full text-center text-xs font-light text-muted-foreground/40">
              Awaiting First Round...
            </div>
          )}
        </div>
        <div className="pt-2">
          <div className="h-[1px] w-full bg-muted-foreground/30" />
        </div>
        <div className="flex items-center justify-between">
          <ButtonIcon Icon={Undo2Icon} onClick={handleUndoRoundScore} />
          {/* {currentTotalScore.includes(13) && (
            <Button className="fade-in" onClick={handleSubmitResult}>
              Complete Match
            </Button>
          )} */}
        </div>
      </div>
      {currentTotalScore.includes(13) && (
        <AlertDialogFooter>
          <AlertDialogAction onClick={handleSubmitResult}>
            Complete Match
          </AlertDialogAction>
        </AlertDialogFooter>
      )}
      {/* <AlertDialogCancel onClick={handleClose}>Cancel Match</AlertDialogCancel> */}
    </div>
  );
};

export const Balls = ({
  amt,
  player,
  externalKey,
}: {
  amt: number;
  player: Person;
  externalKey?: number;
}) => (
  <div className="flex rotate-180 flex-wrap items-center justify-center gap-1">
    {new Array(amt).fill(0).map((_, idx) => (
      <div
        className={`aspect-square h-2 rounded-full bg-${player}`}
        key={`ball_${player}_${amt}_${idx}_${externalKey}`}
      />
    ))}
  </div>
);

// {/* <div className="flex items-center justify-center gap-5">
//           <div className="flex flex-grow basis-0 items-center justify-end">
//             {home}
//           </div>
//           <div>
//             {currentTotalScore[0]} • {currentTotalScore[1]}
//           </div>
//           <div className="flex flex-grow basis-0 items-center justify-start">
//             {away}
//           </div>
//         </div> */}
